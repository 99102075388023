import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../services/authService';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const updateUser = (newUserData) => {
    setUser(newUserData);
    localStorage.setItem('user', JSON.stringify(newUserData));
  };

  const checkAuthStatus = useCallback(async () => {
    const currentUser = authService.getCurrentUser();
    if (currentUser) {
      try {
        const tokenRefreshed = await authService.refreshToken();
        if (tokenRefreshed) {
          const updatedUser = authService.getCurrentUser();
          setUser(prev => {
            if (JSON.stringify(prev) !== JSON.stringify(updatedUser)) {
              // console.log('User state updated after token refresh:', updatedUser);
              return updatedUser;
            }
            return prev;
          });
        }
      } catch (error) {
        console.error('Error checking auth status:', error);
        setUser(null);
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');
      }
    } else {
      setUser(null);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  useEffect(() => {
    const refreshInterval = setInterval(checkAuthStatus, 25 * 60 * 1000); // Check every 25 minutes
    return () => clearInterval(refreshInterval);
  }, [checkAuthStatus]);

  useEffect(() => {
    const handleLogout = () => {
      setUser(null);
      navigate('/login');
    };

    window.addEventListener('logout', handleLogout);
    return () => window.removeEventListener('logout', handleLogout);
  }, [navigate]);

  const login = async (email, password) => {
    const userData = await authService.login(email, password);
    setUser(userData);
    return userData;
  };

  const googleLogin = async (tokenId) => {
    const userData = await authService.googleLogin(tokenId);
    setUser(userData);
    return userData;
  };

  const logout = async () => {
    await authService.logout();
    setUser(null);
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, setUser: updateUser, googleLogin, loading, checkAuthStatus }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext;