// src/config/axios.js
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost/api',
  withCredentials: true,
  headers: {
    'X-API-Key': process.env.REACT_APP_API_KEY
    // 'X-API-Key': process.env.DEVELOPMENT_API_KEY
  }
});

export default instance;