import axiosInstance from '../config/axios';

const API_URL = '/users';

axiosInstance.defaults.withCredentials = true; // This ensures cookies are sent with every request

const authService = {
  refreshTokenPromise: null,

  login: async (email, password) => {
    try {
      const response = await axiosInstance.post(API_URL, {
        action: 'login',
        email,
        password
      });
      if (response.data.error) {
        throw new Error(response.data.error);
      }
      if (response.data.user && response.data.refreshToken) {
        localStorage.setItem('refreshToken', response.data.refreshToken);
        localStorage.setItem('user', JSON.stringify(response.data.user)); 
        return response.data.user;
      }
      throw new Error('Unexpected response from server');
    } catch (error) {
      throw error;
    }
  },

  logout: async () => {
    try {
      await axiosInstance.post(API_URL, { action: 'logout' });
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');
    }
  },

  register: async (email, password) => {
    try {
      const response = await axiosInstance.post(API_URL, {
        action: 'register',
        email,
        password,
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data.message || 'Registration failed');
      } else if (error.request) {
        throw new Error('No response received from server');
      } else {
        throw new Error('Error setting up the request');
      }
    }
  },

  refreshToken: async () => {
    if (authService.refreshTokenPromise) {
      return authService.refreshTokenPromise;
    }
  
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      return false;
    }
  
    authService.refreshTokenPromise = new Promise(async (resolve) => {
      try {
        await new Promise(r => setTimeout(r, 100));
  
        const response = await axiosInstance.post(API_URL, {
          action: 'refresh-token',
          refreshToken: refreshToken
        });
        
        if (response.data.refreshToken && response.data.user) {
          localStorage.setItem('refreshToken', response.data.refreshToken);
          localStorage.setItem('user', JSON.stringify(response.data.user));
          resolve(true);
        } else {
          resolve(false);
        }
      } catch (error) {
        if (error.response?.data?.error === "Refresh token has expired") {
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('user');
          window.dispatchEvent(new CustomEvent('logout'));
        }
        resolve(false);
      } finally {
        authService.refreshTokenPromise = null;
      }
    });
  
    return authService.refreshTokenPromise;
  },

  getCurrentUser: () => {
    const userStr = localStorage.getItem('user');
    return userStr ? JSON.parse(userStr) : null;
  },

  updateProfile: async (profileData) => {
    try {
      const response = await axiosInstance.put(API_URL, profileData, {
        withCredentials: true
      });
  
      if (response.data.user) {
        return response.data.user;
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      throw error;
    }
  },

  forgotPassword: async (email) => {
    const response = await axiosInstance.post(API_URL, {
      action: 'forgot-password',
      email
    });
    return response.data;
  },

  resetPassword: async (token, password) => {
    const response = await axiosInstance.post(API_URL, {
      action: 'reset-password',
      token,
      password
    });
    return response.data;
  },

  verifyEmail: async (token) => {
    const response = await axiosInstance.post(API_URL, {
      action: 'verify-email',
      token
    });
    return response.data;
  },

  setupAxiosInterceptors: (navigate) => {
    axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            const refreshSuccessful = await authService.refreshToken();
            if (refreshSuccessful) {
              return axiosInstance(originalRequest);
            } else {
              throw new Error('Token refresh failed');
            }
          } catch (refreshError) {
            console.error('Token refresh failed:', refreshError);
            authService.logout();
            navigate('/login');
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error);
      }
    );
  },

  googleLogin: async (code) => {
    try {
      const response = await axiosInstance.post(API_URL, {
        action: 'google-login',
        code: code
      });
      
      if (response.data.error) {
        throw new Error(response.data.error);
      }
      
      if (response.data.user && response.data.refreshToken) {
        localStorage.setItem('refreshToken', response.data.refreshToken);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        return response.data.user;
      }
      
      throw new Error('Unexpected response from server');
    } catch (error) {
      console.error('Google login error:', error.response ? error.response.data : error.message);
      throw error;
    }
  },
};

export default authService;