import React from 'react';
import { motion } from 'framer-motion';
import {Cpu, ZapIcon } from 'lucide-react';

const ZapiWalaSpinner = () => (
  // <div className="flex justify-center items-center min-h-screen bg-gray-100">
  //   <div className="relative w-32 h-32">
  //     {/* Green to blue gradient background */}
  //     <div className="absolute inset-0 bg-gradient-to-br from-green-400 to-blue-500 rounded-lg animate-pulse"></div>
      
  //     {/* Spinning border */}
  //     <div className="absolute inset-0 border-4 border-green-200 rounded-lg animate-spin" 
  //          style={{ borderTopColor: 'transparent', animationDuration: '3s' }}></div>
      
  //     {/* CPU icon */}
  //     <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
  //       <Cpu className="w-12 h-12 text-white animate-pulse" />
  //     </div>
      
  //     {/* Text effect with lighter font weight */}
  //     <div className="absolute -bottom-8 left-1/2 transform -translate-x-1/2 w-full text-center">
  //       <span className="text-green-700 font-light text-lg tracking-wide animate-bounce inline-block">
  //         zapiwala.com
  //       </span>
  //     </div>
  //   </div>
  // </div>

  // <div className="flex justify-center items-center min-h-screen">
  //       <motion.div
  //         animate={{ rotate: 360 }}
  //         transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
  //       >
  //         <ZapIcon className="h-12 w-12 text-secondary" />
  //       </motion.div>
  //     </div>

  <div className="flex justify-center items-center p-8">
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
          className="w-16 h-16 border-t-4 border-green-500 border-solid rounded-full"
        />
      </div>
);

export default ZapiWalaSpinner;