import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ScrollToTop from './components/common/ScrollToTop';
import ZapiWalaSpinner from './components/layout/ZapiWalaSpinner';
// Services
import authService from './services/authService';

// Lazy-loaded components
const Header = lazy(() => import('./components/layout/Header'));
const Footer = lazy(() => import('./components/layout/Footer'));
const HomePage = lazy(() => import('./pages/HomePage'));
const ListingDetailPage = lazy(() => import('./pages/ListingDetailPage'));
const AboutPage = lazy(() => import('./pages/AboutPage'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const CategoryPage = lazy(() => import('./pages/CategoryPage'));
const TagPage = lazy(() => import('./pages/TagPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const RegisterPage = lazy(() => import('./pages/RegisterPage'));
const ForgotPasswordPage = lazy(() => import('./pages/ForgotPasswordPage'));
const ResetPasswordPage = lazy(() => import('./pages/ResetPasswordPage'));
const VerifyEmailPage = lazy(() => import('./pages/VerifyEmailPage'));
const ContactPage = lazy(() => import('./pages/ContactPage'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPage'));
const TermsOfService = lazy(() => import('./pages/TermsOfServicePage'));
const PricingLayout = lazy(() => import('./pages/PricingPage'));
const OtherPages = lazy(() => import('./pages/OtherPages'));

// Admin pages
const Dashboard = lazy(() => import('./pages/admin/Dashboard'));
const Listings = lazy(() => import('./pages/admin/Listings'));
const Categories = lazy(() => import('./pages/admin/Categories'));
const AddCategory = lazy(() => import('./pages/admin/AddCategory'));
const EditCategory = lazy(() => import('./pages/admin/EditCategory'));
const Tags = lazy(() => import('./pages/admin/Tags'));
const AddTag = lazy(() => import('./pages/admin/AddTag'));
const EditTag = lazy(() => import('./pages/admin/EditTag'));
const Menus = lazy(() => import('./pages/admin/Menus'));
const AddMenu = lazy(() => import('./pages/admin/AddMenu'));
const EditMenu = lazy(() => import('./pages/admin/EditMenu'));
const Users = lazy(() => import('./pages/admin/Users'));
const AddUser = lazy(() => import('./pages/admin/AddUser'));
const EditUser = lazy(() => import('./pages/admin/EditUser'));
const Settings = lazy(() => import('./pages/admin/Settings'));
const Media = lazy(() => import('./pages/admin/Media'));
const EditListing = lazy(() => import('./pages/admin/EditListing'));
const AddListing = lazy(() => import('./pages/admin/AddListing'));
const SettingsHomePage = lazy(() => import('./pages/admin/SettingsHomePage'));
const SettingsSocialPage = lazy(() => import('./pages/admin/SettingsSocialPage'));
const SettingsFooterPage = lazy(() => import('./pages/admin/SettingsFooterPage'));
const SettingsAdsPage = lazy(() => import('./pages/admin/SettingsAdsPage'));
const SettingsDeletePage = lazy(() => import('./pages/admin/SettingsDeletePage'));
const Pages = lazy(() => import('./pages/admin/Pages'));
const AddPage = lazy(() => import('./pages/admin/AddPage'));
const EditPage = lazy(() => import('./pages/admin/EditPage'));
const LinkTracker = lazy(() => import('./pages/admin/LinkTracker'));
const AdminProfilePage = lazy(() => import('./pages/admin/AdminProfilePage'));
const AdminReviewPage = lazy(() => import('./pages/admin/AdminReviewPage'));
const PricingSettingsPage = lazy(() => import('./pages/admin/PricingSettingsPage'));

// User pages
const UserDashboardPage = lazy(() => import('./pages/user/UserDashboardPage'));
const UserListingsPage = lazy(() => import('./pages/user/UserListingsPage'));
const UserProfilePage = lazy(() => import('./pages/user/UserProfilePage'));
const UserFavoritesPage = lazy(() => import('./pages/user/UserFavoritesPage'));
const UserReviewsPage = lazy(() => import('./pages/user/UserReviewsPage'));
const SubmitListingPage = lazy(() => import('./pages/user/SubmitListingPage'));
const UserEditListingPage = lazy(() => import('./pages/user/UserEditListingPage'));

// Other components
const ProtectedRoute = lazy(() => import('./components/common/ProtectedRoute'));
const RedirectIfAuthenticated = lazy(() => import('./components/auth/RedirectIfAuthenticated'));



const AppRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    authService.setupAxiosInterceptors(navigate);
  }, [navigate]);

  const isAdminRoute = location.pathname.startsWith('/admin');
  const isUserRoute = location.pathname.startsWith('/user');

  return (
    <>
      {!isAdminRoute && !isUserRoute && (
        <Suspense fallback={<ZapiWalaSpinner />}>
          <Header />
        </Suspense>
      )}
      <main className="flex-grow">
        <Suspense fallback={<ZapiWalaSpinner />}>
          <Routes>
            <Route path="/" element={<HomePage key={location.pathname + location.search} />} />
            <Route path="/ai-tools/:id" element={<ListingDetailPage key={location.pathname} />} />
            <Route path="/about-us" element={<AboutPage key={location.pathname} />} />
            <Route path="/contact-us" element={<ContactPage key={location.pathname} />}/>
            <Route path="/privacy-policy" element={<PrivacyPolicy key={location.pathname} />}/>
            <Route path="/terms-and-conditions" element={<TermsOfService key={location.pathname} />}/>
            <Route path="/pricing" element={<PricingLayout key={location.pathname} />}/>
            <Route path="/category/:categoryName" element={<CategoryPage key={location.pathname} />} />
            <Route path="/tag/:tagName" element={<TagPage key={location.pathname} />} />
            
            <Route 
              path="/login" 
              element={
                <RedirectIfAuthenticated>
                  <LoginPage />
                </RedirectIfAuthenticated>
              } 
            />
            <Route 
              path="/register" 
              element={
                <RedirectIfAuthenticated>
                  <RegisterPage />
                </RedirectIfAuthenticated>
              } 
            />
            <Route path="/forgot-password" element={<ForgotPasswordPage key={location.pathname} />} />
            <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
            <Route path="/verify-email/:token" element={<VerifyEmailPage />} />

            {/* Admin routes */}
            <Route path="/admin/*" element={<ProtectedRoute adminOnly={true}><AdminRoutes /></ProtectedRoute>} />

            {/* User routes */}
            <Route path="/user/*" element={<ProtectedRoute><UserRoutes /></ProtectedRoute>} />
            
            {/* OtherPages route */}
            <Route path="/:slug" element={<OtherPages key={location.pathname} />} />

            {/* NotFoundPage should be the last route */}
            <Route path="*" element={<NotFoundPage key={location.pathname} />} />
          </Routes>
        </Suspense>
      </main>
      {!isAdminRoute && !isUserRoute && (
        <Suspense fallback={<ZapiWalaSpinner />}>
          <Footer />
        </Suspense>
      )}
    </>
  );
};

const AdminRoutes = () => {
  return (
    <Routes>
      <Route index element={<Dashboard />} />
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="listings" element={<Listings />} />
      <Route path="categories" element={<Categories />} />
      <Route path="add-category" element={<AddCategory />} />
      <Route path="edit-category/:id" element={<EditCategory />} />
      <Route path="tags" element={<Tags />} />
      <Route path="add-tag" element={<AddTag />} />
      <Route path="edit-tag/:id" element={<EditTag />} />
      <Route path="menus" element={<Menus />} />
      <Route path="add-menu" element={<AddMenu />} />
      <Route path="edit-menu/:id" element={<EditMenu />} />
      <Route path="users" element={<Users />} />
      <Route path="add-user" element={<AddUser />} />
      <Route path="edit-user/:id" element={<EditUser />} />
      <Route path="settings" element={<Settings />} />
      <Route path="media" element={<Media/>}/>
      <Route path="edit-listing/:id" element={<EditListing />} />
      <Route path="add-listing" element={<AddListing />} />
      <Route path="home-settings" element={<SettingsHomePage />} />
      <Route path="social-settings" element={<SettingsSocialPage />} />
      <Route path="footer-settings" element={<SettingsFooterPage />} />
      <Route path="ads-settings" element={<SettingsAdsPage />} />
      <Route path="delete-settings" element={<SettingsDeletePage />} />
      <Route path="pages" element={<Pages />} />
      <Route path="add-page" element={<AddPage />} />
      <Route path="edit-page/:id" element={<EditPage />} />
      <Route path="link-tracker" element={<LinkTracker />} />
      <Route path="profile" element={<AdminProfilePage />} />
      <Route path="reviews" element={<AdminReviewPage />} />
      <Route path="pricing-setting" element={<PricingSettingsPage />} />
    </Routes>
  );
};

const UserRoutes = () => {
  return (
    <Routes>
      <Route index element={<UserDashboardPage />} />
      <Route path="dashboard" element={<UserDashboardPage />} />
      <Route path="listings" element={<UserListingsPage />} />
      <Route path="profile" element={<UserProfilePage />} />
      <Route path="favorites" element={<UserFavoritesPage />} />
      <Route path="reviews" element={<UserReviewsPage />} />
      <Route path="submit-listing" element={<SubmitListingPage />} />
      <Route path="edit-listing/:id" element={<UserEditListingPage />} />
      <Route path="favorites" element={<UserFavoritesPage />} />
    </Routes>
  );
};

function App() {
  return (
    <GoogleOAuthProvider clientId="150277137063-a12dmc9e7jsointrvvd1l802a6b3ilj7.apps.googleusercontent.com">
      <Router>
        <ScrollToTop />
        <AuthProvider>
          <div className="App flex flex-col min-h-screen">
            <AppRoutes />
          </div>
        </AuthProvider>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;